<template>
  <div class="campaign">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- add dialog for campaign -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure want to delete <b>{{ delSalesAgg.carPlate }}</b> form
          Sales Agreement?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dodelSalesAgg()"> Yes </v-btn>
          <v-btn text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add sales Agreement -->
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Sales Agreement
          <!-- {{ addSalesAgreement.name }} -->
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      required
                      :rules="rules.carPlate"
                      v-model="addSalesAgreement.carPlate"
                      label="Car plate*"
                    ></v-text-field>
                  </v-row>
                  <v-row> </v-row>
                  <v-row>
                    <!-- from date  -->
                    <v-col class="py-0">
                      <v-select
                        required
                        :rules="rules.ownerIdType"
                        v-model="addSalesAgreement.ownerIdType"
                        :items="idTypeList"
                        item-value="name"
                        item-text="name"
                        label="ID Type*"
                      >
                      </v-select>
                    </v-col>
                    <!-- to date -->
                    <v-col class="py-0">
                      <v-text-field
                        required
                        :rules="rules.ownerId"
                        v-model="addSalesAgreement.ownerId"
                        label="Owner ID*"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-autocomplete
                        required
                        :rules="rules.carId"
                        v-model="addSalesAgreement.carId"
                        :items="carBrandList"
                        v-on:keyup="carIdSearch"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-database-search"
                        label="Car ID*"
                      >
                        <!-- :search-input.sync="carIdSearch" -->
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        required
                        :rules="rules.dealerShip"
                        v-model="addSalesAgreement.dealerShip"
                        label="Dealership*"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-dialog
                        ref="dialog"
                        v-model="editRegDateModal"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addSalesAgreement.signDate"
                            required
                            :rules="rules.signDate"
                            label="Sign Date*"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          required
                          :rules="rules.signDate"
                          v-model="addSalesAgreement.signDate"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="editRegDateModal = false"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="addSalesAgreement.status"
                        :items="statusType"
                        item-value="value"
                        item-text="text"
                        label="Status"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="addSalesAgreement.remark"
                      label="Remark"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="addSalesAgreement.remarkReject"
                      label="Remark Rejected"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-file-input
                      v-model="attachmentFiles"
                      clearable
                      multiple
                      label="Attachments"
                      accept="image/jpg,image/jpeg,image/png,image/gif,application/pdf"
                    />
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doAddSalesAgg()"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog for campaign -->
    <v-dialog v-model="editDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Sales Agreement
          <!-- {{ editSalesAgreement.name }} -->
        </v-card-title>
        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      required
                      :rules="rules.carPlate"
                      v-model="editSalesAgreement.carPlate"
                      label="Car plate*"
                    ></v-text-field>
                  </v-row>
                  <v-row> </v-row>
                  <v-row>
                    <!-- from date  -->
                    <v-col class="py-0">
                      <v-select
                        required
                        :rules="rules.ownerIdType"
                        v-model="editSalesAgreement.ownerIdType"
                        :items="idTypeList"
                        item-value="name"
                        item-text="name"
                        label="ID Type*"
                      >
                      </v-select>
                    </v-col>
                    <!-- to date -->
                    <v-col class="py-0">
                      <v-text-field
                        required
                        :rules="rules.ownerId"
                        v-model="editSalesAgreement.ownerId"
                        label="Owner ID*"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-autocomplete
                        required
                        :rules="rules.carId"
                        v-model="editSalesAgreement.carId"
                        v-on:keyup="carIdSearch"
                        :items="carBrandList"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-database-search"
                        :placeholder="carIdJSON ? carIdJSON.name : null"
                        label="Car ID*"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        required
                        :rules="rules.dealerShip"
                        v-model="editSalesAgreement.dealerShip"
                        label="Dealership*"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-dialog
                        ref="dialog"
                        v-model="editRegDateModal"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editSalesAgreement.signDate"
                            required
                            :rules="rules.signDate"
                            label="Sign Date*"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          required
                          :rules="rules.signDate"
                          v-model="editSalesAgreement.signDate"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="editRegDateModal = false"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="editSalesAgreement.status"
                        :items="statusType"
                        item-value="value"
                        item-text="text"
                        label="Status"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="editSalesAgreement.remark"
                      label="Remark"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="editSalesAgreement.remarkReject"
                      label="Remark Rejected"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-file-input
                      v-model="attachmentFiles"
                      clearable
                      multiple
                      label="Attachments"
                      accept="image/jpg,image/jpeg,image/png,image/gif,application/pdf"
                    />
                  </v-row>
                </v-col>
              </v-row>

              <!-- attachment files -->
              <v-sheet>
                <v-slide-group
                  v-model="model"
                  active-class="success"
                  show-arrows
                >
                  <v-slide-item v-for="(item, i) in attachmentLists" :key="i">
                    <v-card class="ma-4 att-card" width="100">
                      <a :href="item.path" target="_blank">
                        <img :src="item.path" height="100" width="100" />
                      </a>
                      <v-btn text color="error" @click="deleteAttachment(i)">
                        Delete
                        <!-- <v-icon> mdi-delete </v-icon> -->
                      </v-btn>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doEditSalesAgreement()"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- filter search -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchSalesAgreement()"
          v-model="salesAggSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="filterStatus"
          :items="statusType"
          item-value="value"
          item-text="text"
          label="Sales Agreement Status"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchSalesAgreement()"
          @keyup.enter.native="searchSalesAgreement()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="addDialog = true">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <!-- campaign data listing table -->
    <v-data-table
      :headers="headers"
      :items="salesAggLists"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.carPlate }}</td>
          <td>{{ row.item.ownerIdType }}</td>
          <td>{{ row.item.ownerId }}</td>
          <td>{{ row.item.carId }}</td>
          <td>{{ row.item.dealerShip }}</td>
          <td>
            {{
              row.item.accType === "SP"
                ? "Saleperson"
                : row.item.accType === "CU"
                ? "Customer"
                : ""
            }}
          </td>
          <td>{{ row.item.status }}</td>
          <td>
            {{
              row.item.createTime ? convertDateTime(row.item.createTime) : ""
            }}
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

// import uploadImage from "../../../services/image-uploader";
import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";
import uploadSignedImage from "../../../services/aws-upload-image";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
Vue.use(VueAxios, axios);
Vue.use(DatetimePicker);

export default {
  data() {
    return {
      model: null,
      isLoading: false,
      search: null,
      salesAggSearch: "",
      addDialog: false,
      editDialog: false,
      addStartDateModel: false,
      editStartDateModel: false,
      addToDateModel: false,
      editToDateModel: false,
      editRegDateModal: false,
      deleteDialog: false,
      attachmentFiles: [],
      filterStatus: "",
      statusType: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        // { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      idTypeList: [],
      image: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Car Plate", value: "carPlate" },
        { text: "ID Type", value: "ownerIdType" },
        { text: "Owner ID", value: "ownerId" },
        { text: "Car ID", value: "carId" },
        { text: "Dealer", value: "dealerShip" },
        { text: "Source", value: "accType" },
        { text: "Status", value: "status" },
        { text: "Created Time", value: "createTime" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      salesAggLists: [],
      attachmentLists: [],
      addSalesAgreement: {
        carPlate: null,
        ownerIdType: null,
        ownerId: null,
        dealerShip: null,
        carId: null,
        signDate: null,
        status: null,
        remark: null,
        remarkReject: null,
        path: [],
      },
      editSalesAgreement: {
        carPlate: null,
        ownerIdType: null,
        ownerId: null,
        dealerShip: null,
        carId: null,
        signDate: null,
        status: null,
        remark: null,
        remarkReject: null,
        path: [],
      },
      rules: {
        carPlate: [v => !!v || "Car plate required"],
        ownerIdType: [v => !!v || "Owner Id Type required"],
        carId: [v => !!v || "Car Id required"],
        ownerId: [v => !!v || "Owner Id required"],
        dealerShip: [v => !!v || "Dealership required"],
        signDate: [v => !!v || "Sign date required"],
      },
      delSalesAgg: {
        id: "",
        carPlate: "",
      },
      snackbar: false,
      snackMessage: null,
      carBrandList: [],
      carIdJSON: null,
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchIDType();
    this.fetchSalesAgreement();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchSalesAgreement();
    },
    carIdSearch(event) {
      const inputValue = event.target.value;
      this.fetchCarBrand(inputValue);
    },
    convertDateTime(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD HH:mm");
      return parsedDate;
    },
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD");
      return parsedDate;
    },
    fetchCarBrand(val) {
      this.carBrandList = [];
      const params = {
        sort: "car.createTime,desc",
        productTitle: val,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carUsed`,
        params,
        this.token
      ).then(res => {
        if (res.data) {
          const usedCars = res.data.data.content;
          for (let car of usedCars) {
            if (car) {
              car.name = `${car.productTitle} (${car.id})`;
              this.carBrandList.push(car);
            }
          }
        }
      });

      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carNew`,
        params,
        this.token
      ).then(res => {
        if (res.data) {
          const newCars = res.data.data.content;
          for (let car of newCars) {
            if (car) {
              car.name = `${car.productTitle} (${car.id})`;
              this.carBrandList.push(car);
            }
          }
        }
      });
    },
    getSelectedCarId(carId) {
      if (carId) {
        getAxios(
          `${process.env.VUE_APP_SERVER_URL}/carInfo/carDetailed?carId=${carId}`
        ).then(res => {
          if (res.data) {
            const carDetail = res.data.data.carInfo;
            carDetail.name = `${carDetail.productTitle} (${carDetail.id})`;
            this.carIdJSON = res.data.data.carInfo;
          }
        });
      }
    },
    fetchIDType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/idTypePair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.idTypeList = res.data.data.content;
        }
      });
    },
    searchSalesAgreement() {
      this.fetchSalesAgreement();
    },
    deleteAttachment(index) {
      this.attachmentLists.splice(index, 1);
    },
    fetchSalesAgreement() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        status: self.filterStatus,
        searchContent: self.salesAggSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/vehicleSalesAgreementSystem/vehicleSalesAgreementData`,
        params
      )
        .then(res => {
          if (res.data) {
            self.salesAggLists = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchSalesAgreement();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDialog = true;
    },
    setEditItemInfo(item) {
      if (item) {
        this.editSalesAgreement.id = item.id;
        this.editSalesAgreement.carPlate = item.carPlate;
        this.editSalesAgreement.ownerIdType = item.ownerIdType;
        this.editSalesAgreement.ownerId = item.ownerId;
        // this.editSalesAgreement.carId = item.carId;
        this.editSalesAgreement.dealerShip = item.dealerShip;
        this.editSalesAgreement.signDate = item.signDate
          ? this.convertDate(item.signDate)
          : "";
        this.editSalesAgreement.status = item.status;
        this.editSalesAgreement.remark = item.remark;
        this.editSalesAgreement.remarkReject = item.remarkReject;
        this.attachmentLists = item.listPath;
        this.getSelectedCarId(item.carId);
      }
    },
    resetEditItemInfo() {
      this.editSalesAgreement.id = null;
      this.editSalesAgreement.carPlate = null;
      this.editSalesAgreement.ownerIdType = null;
      this.editSalesAgreement.ownerId = null;
      this.editSalesAgreement.carId = null;
      this.editSalesAgreement.dealerShip = null;
      this.editSalesAgreement.signDate = null;
      this.editSalesAgreement.status = null;
      this.editSalesAgreement.remark = null;
      this.editSalesAgreement.remarkReject = null;
      this.editSalesAgreement.path = [];
      this.attachmentFiles = [];
      this.attachmentLists = [];
      this.carIdJSON = null;
    },
    resetAddItemInfo() {
      this.addSalesAgreement.carPlate = null;
      this.addSalesAgreement.ownerIdType = null;
      this.addSalesAgreement.ownerId = null;
      this.addSalesAgreement.carId = null;
      this.addSalesAgreement.dealerShip = null;
      this.addSalesAgreement.signDate = null;
      this.addSalesAgreement.status = null;
      this.addSalesAgreement.remark = null;
      this.addSalesAgreement.remarkReject = null;
      this.addSalesAgreement.path = [];
      this.attachmentFiles = [];
      this.attachmentLists = [];
      this.carIdJSON = null;
    },
    async doAddSalesAgg() {
      const self = this;
      try {
        if (this.$refs.form.validate()) {
          for (let item of this.attachmentFiles) {
            const format = item.type.split("/")[1];
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: format,
                waterMark: false,
              },
              this.token
            );
            await uploadSignedImage(item, uploadUrl.data.originalUrl);
            uploadUrl.data.originalName
              ? this.addSalesAgreement.path.push(uploadUrl.data.originalName)
              : this.addSalesAgreement.path;
          }
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/vehicleSalesAgreementSystem/addVehicleSalesAgreement`,
            this.addSalesAgreement
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.addDialog = false;
                this.carIdJSON = null;
                this.snackbar = true;
                this.snackMessage = "Sales Agreement added successfully...";
                self.fetchSalesAgreement();
                self.resetAddItemInfo();
                this.$refs.form.reset();
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
        this.snackbar = true;
        this.snackMessage = "Error!";
      }
    },
    async doEditSalesAgreement() {
      const self = this;
      try {
        if (this.carIdJSON && !this.editSalesAgreement.carId) {
          this.editSalesAgreement.carId = this.carIdJSON
            ? this.carIdJSON.id
            : null;
        }
        if (this.$refs.form.validate()) {
          if (self.attachmentLists.length > 0) {
            for (let item of this.attachmentLists) {
              const imagePath = item.path.split(
                "https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/"
              )[1];
              self.editSalesAgreement.path.push(imagePath);
            }
          }
          for (let item of this.attachmentFiles) {
            const format = item.type.split("/")[1];
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: format,
                waterMark: false,
              },
              this.token
            );
            await uploadSignedImage(item, uploadUrl.data.originalUrl);
            self.editSalesAgreement.path.push(uploadUrl.data.originalName);
          }
          if (!self.editSalesAgreement.path) {
            self.editSalesAgreement.path = [];
          }

          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/vehicleSalesAgreementSystem/updVehicleSalesAgreement`,
            self.editSalesAgreement
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.editDialog = false;
                this.carIdJSON = null;
                self.fetchSalesAgreement();
                self.resetEditItemInfo();
                this.$refs.form.reset();
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    delButtonClick(item) {
      this.delSalesAgg.id = item.id;
      this.delSalesAgg.carPlate = item.carPlate;
      this.deleteDialog = true;
    },
    dodelSalesAgg() {
      const self = this;
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/vehicleSalesAgreementSystem/delVehicleSalesAgreement?vehicleSalesAgreementId=${self.delSalesAgg.id}`,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchSalesAgreement();
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
  },
};
</script>
<style>
.v-tabs-bar {
  display: none;
}
.att-card {
  cursor: pointer;
  text-align: center;
}
.att-card:hover {
  box-shadow: 5px 5px 5px 5px;
}
</style>
